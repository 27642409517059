import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Resources`}</h1>
    <h2>{`Sketch`}</h2>
    <h3>{`How to Use Sketch`}</h3>
    <p>{`Sketch is the preferred software for Mac users in the field of web design. It is made by Bohemian Labs and can be purchases as a student for around $50. It is a wonderful combination of many of the strengths of Adobe Illustrator and Adobe Photoshop with some unique features of its own. It also integrates smoothly with other popular web tools such as Invision.`}</p>
    <p>{`Read more from the `}<a parentName="p" {...{
        "href": "https://sketchapp.com/"
      }}>{`Sketch website »`}</a></p>
    <h3>{`Getting Started with Sketch`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Obtain a License`}</strong></p>
        <p parentName="li">{`University students may have access to licenses of Sketch in select campus computer labs. Look for instructions from your professor for how to use this resource.`}</p>
        <p parentName="li">{`If you would like to obtain a license for your own computer you may purchase one with a student discount. `}<a parentName="p" {...{
            "href": "https://www.sketch.com/store/edu/"
          }}>{`Learn more »`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Learn Sketch`}</strong></p>
        <p parentName="li">{`If you’ve used Adobe software such as Illustrator or Photoshop you will find Sketch easy to learn.`}</p>
        <p parentName="li">{`Here is `}<a parentName="p" {...{
            "href": "https://youtu.be/qywB0JHQeC4"
          }}>{`a nice overview of Sketch`}</a></p>
        <p parentName="li">{`InVision recently made `}<a parentName="p" {...{
            "href": "http://switchtosketchapp.com/"
          }}>{`a very nice set of tutorials`}</a>{` to help folks get started with Sketch. Note that you’ll need to provide an email address in order to view these tutorials. This is a trusted site that sends a regular newsletter with excellent interactive design content.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Install Craft`}</strong></p>
        <p parentName="li">{`Craft is a plugin for Sketch built by InVision that helps Sketch and InVision work smoothly together. Craft is discussed in the tutorials above so if you skipped over them you can learn more about Craft and download it from the `}<a parentName="p" {...{
            "href": "https://www.invisionapp.com/craft"
          }}>{`Craft webpage`}</a>{`.`}</p>
        <p parentName="li">{`In University labs, the Craft Manager plugin should be available on any computer on which Sketch is installed. Look for details from your professor regarding this resource.`}</p>
      </li>
    </ol>
    <h2>{`Invision`}</h2>
    <h3>{`How to Use InVision`}</h3>
    <p>{`InVision is a web-based tool for creating prototype mockups of websites. You essentially design screen mockups using other software, upload those screens to InVision, and then add hotspots and transitions in order to simulate how the site or application should work. The result is a prototype that you can review with users in user testing, discuss with clients, and collaborate on with colleagues.`}</p>
    <p>{`Read more from the `}<a parentName="p" {...{
        "href": "https://www.invisionapp.com/"
      }}>{`InVision website »`}</a></p>
    <p>{`In our courses we use InVision for design review, critique, and feedback.`}</p>
    <h3>{`Getting Started with InVision`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Create and account`}</strong></p>
        <p parentName="li">{`University students can create an account with InVision for free using information provided by your professor that should include an access code.`}</p>
        <p parentName="li">{`Your student account will remain active until 6 months after graduation and allows you unlimited projects in the meantime.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Learn InVision`}</strong></p>
        <p parentName="li">{`After creating an account with InVision you can log in and learn more about how to use the tool.`}</p>
        <p parentName="li">{`InVision also created `}<a parentName="p" {...{
            "href": "https://invisionapp.wistia.com/medias/5daefdvykt"
          }}>{`an introductory video specifically for students`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Use InVision with Sketch`}</strong></p>
        <p parentName="li">{`InVision built a very neat plugin for Sketch and other tools called Craft. This enables some very helpful features and the ability to synchronize screens in a project straight from art boards in Sketch. `}<a parentName="p" {...{
            "href": "https://www.invisionapp.com/craft"
          }}>{`Learn more »`}</a></p>
        <p parentName="li">{`When working between Sketch and InVision you'll use Craft to keep the two in sync. Here's the overall process:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Create a project in InVision. See project details to ensure you set up the right kind of project.`}</li>
          <li parentName="ul">{`Complete your design work in Sketch.`}</li>
          <li parentName="ul">{`Use the Craft plugin to connect to your InVision account and upload screens to the correct project. If you're doing this for the first time, make sure the Craft Manager application is running and that you've used it to install the plugin in Sketch.`}</li>
          <li parentName="ul">{`Back in InVision you can set up the hot spots/links and other interactive simulations on your screens from Sketch.`}</li>
          <li parentName="ul">{`If you make changes to the screens in Sketch be sure to upload the updates to InVision again and double-check your interactive elements in InVision. `}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Use InVision for Design Feedback and Revisions`}</strong></p>
        <p parentName="li">{`As we will be using the Craft plugin to synchronize design comps from Sketch to a project in InVision your professor will use InVision’s commenting feature to provide feedback. Here is an overview of the typical process:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`Add your professor as a collaborator on the project in InVision.`}</li>
          <li parentName="ol">{`Use the provided submission in CULearn to indicate that you’re ready for feedback or have completed the assignment.`}</li>
          <li parentName="ol">{`Your professor will use the comment and workflow tools to provide feedback on any screens.`}</li>
          <li parentName="ol">{`You can review feedback by clicking on the project and then the Comments tab. Your professor might also use the Workflow tool to further amplify this process, so watch for announcements about this feature.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Use Inspect`}</strong></p>
        <p parentName="li">{`InVision has released an excellent extended feature called Inspect that allows you to view any screen of a project uploaded from Sketch and other tools and gather technical settings from it. This includes actual CSS code, measurements, colors, and even quick access to slices exported from Sketch.`}</p>
        <p parentName="li"><a parentName="p" {...{
            "href": "https://support.invisionapp.com/hc/en-us/articles/207950906-Introduction-to-Inspect"
          }}>{`Learn more about Inspect »`}</a></p>
        <p parentName="li">{`Note that this tool is not a substitution for good CSS practice; you should not simply copy and paste everything Inspect provides. Instead, it is a) a helpful reference you can use for measurements while building a comp and b) provides quick access to copy and paste necessary content and settings.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      